import React, { useEffect, useState } from 'react'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Skeleton,
  TableFooter
} from '@mui/material'
import { Home, ListAlt, Person } from '@mui/icons-material'
import moment from 'moment'
import { graphql, navigate, useStaticQuery } from 'gatsby'

import { useProfileContext } from '../contexts/profileContext'
import { routePaths } from '../constants/routes'
import Header from '../components/common/Header'
import { SubContainer } from '../components/common/Styled'
import { formatPrice, formatStatus } from '../helpers'
import { Order, OrderStatus } from '../helpers/types'
import OrdersFilterMenu from '../components/OrdersFilterMenu'
import SEO from '../components/common/seo'
import { fetchOrders } from '../helpers/api/order'

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
  }
`

const OrdersPage = () => {
  const [filters, setFilters] = useState<OrderStatus[]>([])
  const [loading, setLoading] = useState(true)
  const user = useProfileContext()?.user
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [orders, setOrders] = useState<Order[]>([])

  const { home } = useStaticQuery(query)

  useEffect(() => {
    const getOrders = async () => {
      const result = await fetchOrders(filters)
      setOrders(result)
      setLoading(false)
    }
    if (user) getOrders()
  }, [user, filters])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeFilters = (value: OrderStatus) => () => {
    const currentIndex = filters.indexOf(value)
    const newFilters = [...filters]

    if (currentIndex === -1) {
      newFilters.push(value)
    } else {
      newFilters.splice(currentIndex, 1)
    }

    setFilters(newFilters)
  }

  const filteredOrders = orders?.filter(
    order => order.status !== OrderStatus.UNPAID
  )

  const resetFilter = () => setFilters([])

  if (!user) return null

  const title = 'Mes commandes'
  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <Person />,
      label: 'Mon compte',
      route: routePaths.profile
    },
    {
      icon: <ListAlt />,
      label: title
    }
  ]

  return (
    <>
      <SEO title="Commandes" />
      <SubContainer>
        <Header
          {...{ items, title }}
          filter={
            <OrdersFilterMenu
              {...{ handleChangeFilters, filters, resetFilter }}
            />
          }
        />
        <Typography style={{ marginTop: 20 }}>
          Vous trouverez ici vos commandes passées depuis la création de votre
          compte
        </Typography>
        <TableContainer
          component={Paper}
          style={{ marginTop: 20, maxHeight: '70vh' }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '80px' }}>N° de commande</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Prix total</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell style={{ width: '100px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading &&
                new Array(3).fill('').map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="right" style={{ width: '100px' }}>
                      <Skeleton height="45px" />
                    </TableCell>
                  </TableRow>
                ))}
              {!loading &&
                filteredOrders.length > 0 &&
                filteredOrders
                  .sort(
                    (a, b) =>
                      (new Date(b.orderDate) as any) -
                      (new Date(a.orderDate) as any)
                  )
                  .map(order => (
                    <TableRow key={order.id}>
                      <TableCell component="th" scope="row">
                        {order.id}
                      </TableCell>
                      <TableCell>
                        {moment.utc(order.orderDate).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {formatPrice(order.totalPrice + order.deliveryPrice)}
                      </TableCell>
                      <TableCell align="center">
                        {formatStatus(order.status)}
                      </TableCell>
                      <TableCell align="right" style={{ width: '100px' }}>
                        <Button
                          onClick={() =>
                            navigate(`${routePaths.order}?id=${order.id}`)
                          }
                          variant="contained"
                          color="primary"
                        >
                          Détails
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              {!loading && filteredOrders.length === 0 && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    colSpan={5}
                  >
                    Vous n&#39;avez pas encore de commandes
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  count={filteredOrders.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </SubContainer>
    </>
  )
}
export default OrdersPage
